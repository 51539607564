<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row align-h="center">
        <b-col cols="8">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group label="Warehouse" label-for="warehouse">
                  <v-select
                    id="warehouse"
                    v-model="form.selectedWarehouse"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="form.optionsWarehouse"
                    placeholder="Select 1 Warehouse"
                  />
                  <!-- Selected: <strong>{{ form.selectedWarehouse }}</strong> -->
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Date" label-for="date">
                  <flat-pickr
                    id="date"
                    v-model="form.date"
                    class="form-control"
                    :config="{ dateFormat: 'd-m-Y' }"
                    required
                  />
                  <!-- Selected: <strong>{{ form.date }}</strong> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Vendor" label-for="vendor">
              <v-select
                id="vendor"
                v-model="form.selectedVendor"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="form.optionsVendor"
                placeholder="Select Vendors (multiple)"
                multiple
              />
              <!-- Selected: <strong>{{ form.selectedVendor }}</strong> -->
            </b-form-group>
            <b-form-group label="Category" label-for="category-vendor">
              <v-select
                id="category-vendor"
                v-model="form.selectedCategory"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="form.optionsCategory"
                placeholder="Select Categories (multiple)"
                multiple
              />
              <!-- Selected: <strong>{{ form.selectedCategory }}</strong> -->
            </b-form-group>
            <b-form-group label="Employee" label-for="employee">
              <v-select
                id="employee"
                v-model="form.selectedEmployee"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="form.optionsEmployee"
                placeholder="Select Employees (multiple)"
                multiple
              />
              <!-- Selected: <strong>{{ form.selectedEmployee }}</strong> -->
            </b-form-group>
            <b-form-group label="PIC" label-for="pic">
              <v-select
                id="pic"
                v-model="form.selectedPIC"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="form.optionsPIC"
                placeholder="Select 1 PIC"
              />
              <!-- Selected: <strong>{{ form.selectedPIC }}</strong> -->
            </b-form-group>
            <b-form-group label="Description" label-for="description">
              <b-form-textarea
                id="description"
                placeholder="Description"
                v-model="form.description"
              />
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mt-1"
              @click="addStockOpname()"
            >
              Submit
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    ToastificationContent,
    vSelect,
    flatPickr,
    BFormTextarea,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BContainer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        date: null,
        selectedWarehouse: null,
        optionsWarehouse: [],
        selectedEmployee: [],
        optionsEmployee: [],
        selectedPIC: null,
        optionsPIC: [],
        selectedCategory: [],
        optionsCategory: [],
        selectedVendor: [],
        optionsVendor: [],
        description: "",
      },
      employees: [],
      categories: [],
      vendors: [],
      errors: "",
      errMessage: "",
    };
  },
  watch: {
    "form.selectedEmployee": function (data) {
      this.form.optionsPIC.splice(0);
      this.form.optionsPIC = data;
    },
    "form.selectedCategory": function (data) {
      this.form.optionsVendor.splice(0);
      var $datas = data;
      var i;
      for (i = 0; i < $datas.length; i++) {
        var $categoryID = $datas[i].value;
        this.getVendor($categoryID);
      }
    },
  },
  created() {
    this.getWarehouse();
    this.getEmployees();
    this.getCategory();
  },
  methods: {
    getWarehouse() {
      this.$http.get("warehouses").then((response) => {
        // console.log(response.data.data)
        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.optionsWarehouse.push($data);
        }
        // console.log(this.form.optionsWarehouse)
      });
    },
    getEmployees() {
      this.$http.get("employees").then((response) => {
        // console.log(response.data.data)
        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.optionsEmployee.push($data);
        }
        // console.log(this.form.optionsEmployee)
      });
    },
    getCategory() {
      this.$http.get("product/categories/all").then((response) => {
        // console.log(response.data.data)
        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.form.optionsCategory.push($data);
          } else {
            // var $dataParent = {
            //     value: datas[i].id,
            //     text: datas[i].name,
            //     disabled: true
            // }
            // this.form.optionsCategory.push($dataParent)

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.form.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.form.optionsCategory)
      });
    },
    getVendor(id) {
      this.$http
        .get("vendor/product/category/" + id)
        .then((response) => {
          // console.log(response.data.data)
          var datas = response.data.data;
          var i;
          for (i = 0; i < datas.length; i++) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            this.form.optionsVendor.push($data);
          }
          // console.log(this.form.optionsVendor)
        });
    },
    addStockOpname() {
      if (this.form.date === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Date field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedWarehouse === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Warehouse field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedEmployee.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Employee field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedPIC === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The PIC field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedCategory.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Category field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedVendor.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Vendor field is required",
            variant: "warning",
          },
        });
      } else {
        if (this.form.selectedWarehouse !== null) {
          var $warehouse = this.form.selectedWarehouse.value;
        }
        if (this.form.selectedEmployee.length !== 0) {
          let datas = this.form.selectedEmployee;
          var i;
          this.employees.splice(0);
          for (i = 0; i < datas.length; i++) {
            var $data = datas[i].value;
            this.employees.push($data);
          }
          // console.log(this.employees)
        }
        if (this.form.selectedPIC !== null) {
          var $pic = this.form.selectedPIC.value;
        }
        if (this.form.selectedCategory.length !== 0) {
          let datas = this.form.selectedCategory;
          var i;
          this.categories.splice(0);
          for (i = 0; i < datas.length; i++) {
            var $data = datas[i].value;
            this.categories.push($data);
          }
          // console.log(this.categories)
        }
        if (this.form.selectedVendor.length !== 0) {
          let datas = this.form.selectedVendor;
          var i;
          this.vendors.splice(0);
          for (i = 0; i < datas.length; i++) {
            var $data = datas[i].value;
            this.vendors.push($data);
          }
          // console.log(this.vendors)
        }
        this.$http
          .post("warehouse/stock-opname/add", {
            date: this.form.date,
            warehouse_id: $warehouse,
            worker: this.employees,
            pic: $pic,
            category: this.categories,
            vendor_id: this.vendors,
            description: this.form.description,
          })
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Success add Stock Opname",
                variant: "success",
              },
            });
            location.href = "/warehouse/stock-opname";
            // console.log(response.data.data)
          })
          .catch((errors) => {
            this.errMessage = errors.response.data.message;
            // this.$toast({
            //     component: ToastificationContent,
            //     props: {
            //         title: 'Notification',
            //         icon: 'BellIcon',
            //         text: this.errMessage,
            //         variant: 'warning',
            //     },
            // })
            console.log(errors.response);
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>